<template>
  <v-container class="client-registration-form">
    <v-card-title class="text-h5">Aktywacja profilu</v-card-title>

    <v-card-text class="activation-message">Podczas aktywacji pierwszego profilu, automatycznie utworzymy Twoje konto użytkownika. Hasło do logowania otrzymasz na podany&nbsp;e-mail.
    </v-card-text>

    <br>
    <v-card-text>
      <v-form v-model="valid" @submit.prevent="submitActivation">
        <!-- Client Email Field with Validation -->
        <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            type="email"
        ></v-text-field>

        <!-- Activation Key Field -->
        <v-text-field
            v-model="activation_key"
            :rules="activationCodeRules"
            label="Klucz aktywacyjny"
            type="text"
        ></v-text-field>

        <v-btn :disabled="!valid" color="primary activation-button" size="large" type="submit">Aktywuj profil</v-btn>
      </v-form>
    </v-card-text>
  </v-container>
</template>

<script>
import { toast } from "vue3-toastify";
import { emailPatternRule, fieldRequiredRule } from "@/utils/formValidators";
import axiosInstance from "@/interceptors/axios.config";

export default {
  data() {
    return {
      valid: false,
      email: '',
      activation_key: '',
      apiUrl: process.env.VUE_APP_API_URL,
      emailRules: [
        fieldRequiredRule(),
        emailPatternRule(),
      ],
      activationCodeRules: [
        fieldRequiredRule(),
      ],
    };
  },
  methods: {
    async submitActivation() {
      const form = new FormData();
      form.append("email", this.email);
      form.append("activation_key", this.activation_key);

      try {
        const response = await axiosInstance.post(`${this.apiUrl}registration-business/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
          requiresReCaptcha: true,
          reCaptchaAction: 'register',
        });

        toast.success(response.data.message);

        setTimeout(() => {
          this.$router.push({ name: 'dashboard' });
        }, 3500);
      } catch (error) {
        console.error('Wystąpił błąd. Sprawdź dane i spróbuj ponownie.')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client-registration-form {
  max-width: 400px;
  margin-top: 32px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  div {
    display: block; /* Override flex display */
    margin-bottom: 10px; /* Space between form groups */

    input {
      width: calc(100% - 22px); /* Adjust width to account for padding and border */
      padding: 10px; /* Adjust padding if needed */
      border: 1px solid #ccc; /* Add border to match login form style */
      border-radius: 4px; /* Slight rounding of corners to match login form style */
    }
  }

  input {
    font-weight: normal;
  }

  button {
    width: 100%; /* Full width for the button */
    padding: 10px 20px; /* Adjust padding if needed */
    margin-top: 10px; /* Space between buttons */
    cursor: pointer; /* Change cursor to pointer */
    background-color: black; /* Button background color */
    color: white; /* Button text color */
    border: none; /* No border for the button */
    border-radius: 4px; /* Slight rounding of corners */

    &:hover {
      background-color: #333; /* Darken button on hover */
    }
  }

  label,
  button {
    font-weight: bold;
  }
}

.activation-button {
  text-transform: none;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 15px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.activation-message {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
